// Third party
import React from "react";
import { graphql } from "gatsby";

// Components
import Layout from "../components/Layout";
import Section from "../components/Section";
import Container from "../components/Container";
import Hero from "../components/Hero";
import Breadcrumb from "../components/Breadcrumb";
import ContentList from "../components/ContentList";
import Seo from "../components/seo";

const breadcrumbs = [
  {
    id: 1,
    title: "Home",
    slug: "/",
  },
  {
    id: 2,
    title: "Testimonials",
    slug: "/testimonials/",
  },
];

const TestimonialsPage = ({ data }) => {
  const {
    allContentfulTestimonial: { edges: items },
  } = data;
  return (
    <Layout>
      <Seo
        title="Testimonials"
        description={"Lees hier informatie over de Hormoon Yoga ervaringen"}
        slug={"testimonials"}
      />
      <Hero small>
        <Container centered>
          <h1>Testimonials</h1>
          <p>Wat vindt de klant?</p>
        </Container>
      </Hero>
      <Breadcrumb items={breadcrumbs} />
      <Section>
        <Container>
          <ContentList items={items} parent="testimonials" />
        </Container>
      </Section>
    </Layout>
  );
};

export default TestimonialsPage;

export const query = graphql`
  query TestimonialsQuery {
    allContentfulTestimonial(
      filter: { node_locale: { eq: "nl" } }
      limit: 1000
    ) {
      edges {
        node {
          id
          title
          slug
          createdAt
          avatar {
            fixed(width: 448, height: 448) {
              src
            }
          }
          body {
            childMarkdownRemark {
              excerpt
              html
            }
          }
        }
      }
    }
  }
`;
